export const skillsData = [
    "React.js",
    "Typescript",
    "React native",
    "UX design",
    "Figma",
    "AdobeXD",
    "Atomic design",
    "Reusable components",
    "Wordpress",
    "Webflow",
    "Responsive web design",
    "Tailwind CSS",
    "Vanilla JavaScript",
    "Material UI",
    "Bootstrap",
    "HTML",
    "CSS",
    "Python",
    "MongoDB",
    "Hubspot",
    "Hubspot change streams",
    "REST APIs",
    "Express",
    "Shopify",
    "Shopify liquid",
    "Contentful",
    "Customisable white label products",
    "Stripe integration",
    "Android development",
    "iOS development",
    "Keyword strategies",
    "Google analytics",
    "User behaviour tracking",
    "Google tag manager",
    "A/B testing",
    "Site performance optimization",
    "SEO strategies",
    "Technical SEO",
    "Google ads",
    "Agile environments",
    "Git",
    "Version control",
    "Cypress testing",
    "Pixel-perfect design reproduction",
    "Sales",
    "Web copy",
    "Blogs",
    "Jira",
    "Linear",
    "Github",
    "Bitbucket",
  ];